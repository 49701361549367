import React, { useState } from "react";

const clubs = [
  {
    nomclub: "US Bouscat Judo Taïso",
    logo_url:
      "https://res.cloudinary.com/dy5kblr32/image/upload/v1715898537/images/settings/logo_club_zm3ybt.png",
    lien_url: "https://www.usbouscat-judo.com",
    discipline: "Judo",
  },
  {
    nomclub: "US Bouscat Karaté",
    logo_url:
      "https://res.cloudinary.com/dy5kblr32/image/upload/v1724876971/fake/join_hxza6c.png",
    lien_url: "https://www.usbouscat-karate.com",
    discipline: "Karaté",
  },
  {
    nomclub: "ESSHA Judo Segré",
    logo_url:
      "https://res.cloudinary.com/dj0c0zuje/image/upload/v1732802429/logo_essha_judo_segre_l2p9kz.png",
    lien_url: "https://www.essha-judo-segre.com/",
    discipline: "Judo",
  },
  {
    nomclub: "Dojo Gujanais",
    logo_url:
      "https://res.cloudinary.com/dj0c0zuje/image/upload/v1733150509/logo_dojo_gujanais_qayonu.jpg",
    lien_url: "https://www.dojogujanais.fr/",
    discipline: "Judo",
  },
  {
    nomclub: "Judo Club Salon de Provence",
    logo_url:
      "https://res.cloudinary.com/dj0c0zuje/image/upload/v1730982132/JUDOCLUBSALON/settings/Original.png1730982131933.png",
    lien_url: "https://www.judoclubsalon.fr/",
    discipline: "Judo",
  },
  {
    nomclub: "Tanguy Sensei",
    logo_url:
      "https://res.cloudinary.com/dj0c0zuje/image/upload/v1736435060/logo_tanguy_sensei_wvpvtv.png",
    lien_url: "https://www.tanguy-sensei.com/",
    discipline: "Site Web",
  },
  {
    nomclub: "Saint-Malo Brazilian Jiu-Jitsu",
    logo_url:
      "https://res.cloudinary.com/dj0c0zuje/image/upload/v1738259354/logo_saintmalobjj_gbqajm.png",
    lien_url: "https://www.saint-malo-bjj.com/",
    discipline: "Ju-Jitsu Brésilien",
  },
  {
    nomclub: "Judo Club Plombières (BEL)",
    logo_url:
      "https://res.cloudinary.com/dj0c0zuje/image/upload/v1738831849/logo_jcp_512_bruzft.png",
    lien_url: "https://www.judoclubplombieres.be/",
    discipline: "Judo",
  },
  {
    nomclub: "Judo Club du Bocage Bressuirais",
    logo_url:
      "https://res.cloudinary.com/dj0c0zuje/image/upload/v1738917579/logo_sdtvbf.jpg",
    lien_url: "https://www.jcbb.fr/",
    discipline: "Judo",
  },
  {
    nomclub: "Comité de Judo de Gironde",
    logo_url:
      "https://res.cloudinary.com/dj0c0zuje/image/upload/v1739205183/logo_cd33_aurj4v.png",
    lien_url: "https://www.judogironde.fr/",
    discipline: "Judo",
  },
  {
    nomclub: "Union Sportive Bouscataise",
    logo_url:
      "https://res.cloudinary.com/dj0c0zuje/image/upload/v1729456718/logo_USB_blanc_u5vira.webp",
    lien_url: "https://www.union-sportive-bouscataise.com/",
    discipline: "Omnisports",
  },
];

const disciplines = [...new Set(clubs.map((club) => club.discipline))];

const ClubPartners = () => {
  const [selectedDiscipline, setSelectedDiscipline] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const clubsPerPage = 6;

  const filteredClubs = selectedDiscipline
    ? clubs.filter((club) => club.discipline === selectedDiscipline)
    : clubs;

  const totalPages = Math.ceil(filteredClubs.length / clubsPerPage);
  const startIndex = (currentPage - 1) * clubsPerPage;
  const selectedClubs = filteredClubs.slice(
    startIndex,
    startIndex + clubsPerPage
  );

  return (
    <section className="py-8 bg-gray-100 dark:bg-neutral-900 text-gray-800 dark:text-gray-200">
      <div className="container mx-auto px-4">
        <div className="text-center mb-8">
          <h2 className="text-2xl font-bold text-orange-600">
            Clubs Partenaires
          </h2>
          <p className="text-gray-600 dark:text-gray-400">
            Découvrez les clubs qui utilisent MonAppliClub.
          </p>
        </div>

        <div className="mb-6 text-center">
          <label className="text-gray-700 dark:text-gray-300 mr-2">
            Filtrer par discipline :
          </label>
          <select
            value={selectedDiscipline}
            onChange={(e) => {
              setSelectedDiscipline(e.target.value);
              setCurrentPage(1);
            }}
            className="px-3 py-2 border border-gray-300 rounded-md bg-white dark:bg-neutral-800 text-gray-700 dark:text-gray-300"
          >
            <option value="">Toutes</option>
            {disciplines.map((discipline, idx) => (
              <option key={idx} value={discipline}>
                {discipline}
              </option>
            ))}
          </select>
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-3 gap-6">
          {selectedClubs.map((club, idx) => (
            <div
              key={idx}
              className="flex flex-col items-center bg-white dark:bg-neutral-800 p-4 rounded-lg shadow-lg transition transform hover:scale-105"
            >
              <img
                src={club.logo_url}
                alt={club.nomclub}
                className="w-full h-32 mb-2 aspect-auto object-contain"
              />
              <h3 className="text-lg text-center font-semibold text-gray-700 dark:text-gray-300">
                {club.nomclub}
              </h3>
              <a
                href={club.lien_url}
                target="_blank"
                rel="noopener noreferrer"
                className=" text-blue-500 hover:text-blue-700"
              >
                Voir le site
              </a>
            </div>
          ))}
        </div>

        <div className="mt-6 flex justify-center items-center gap-4">
          <button
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className="px-4 py-2 bg-orange-500 text-white rounded-md disabled:opacity-50"
          >
            Précédent
          </button>

          <span className="text-gray-700 dark:text-gray-300">
            Page {currentPage} / {totalPages}
          </span>

          <button
            onClick={() =>
              setCurrentPage((prev) => Math.min(prev + 1, totalPages))
            }
            disabled={currentPage === totalPages}
            className="px-4 py-2 bg-orange-500 text-white rounded-md disabled:opacity-50"
          >
            Suivant
          </button>
        </div>
      </div>
    </section>
  );
};

export default ClubPartners;
