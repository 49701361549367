import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { IoIosMenu, IoIosClose } from "react-icons/io";
import { FaSignOutAlt, FaMoon, FaSun } from "react-icons/fa"; // Import des icônes
import "./LinkNav.css";

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false); // Nouvel état pour le mode sombre
  const [userInfo, setUserInfo] = useState({
    photoURL: "",
    nom: "",
    prenom: "",
    role_id: null,
  });

  const navigate = useNavigate();

  useEffect(() => {
    // Vérifier si l'utilisateur est connecté et récupérer ses informations
    const token = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("userInfo"));
    if (token && user) {
      setIsAuthenticated(true);
      setUserInfo({
        photoURL: user.photoURL || "/img/user_avatar.jpg",
        nom: user.nom,
        prenom: user.prenom,
        role_id: user.role_id,
      });
    }

    const handleScroll = () => {
      if (window.scrollY > 100) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleStorageChange = () => {
      const token = localStorage.getItem("token");
      const user = JSON.parse(localStorage.getItem("userInfo"));
      if (token && user) {
        setIsAuthenticated(true);
        setUserInfo({
          photoURL: user.photoURL || "/img/user_avatar.jpg",
          nom: user.nom,
          prenom: user.prenom,
          role_id: user.role_id,
        });
      } else {
        setIsAuthenticated(false);
        setUserInfo({
          photoURL: "",
          nom: "",
          prenom: "",
          role_id: null,
        });
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  // Gestion du mode sombre
  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
    if (!isDarkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleHomeClick = () => {
    navigate("/");
    window.scrollTo({ top: 0, behavior: "smooth" });
    setIsOpen(false);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userInfo");
    setIsAuthenticated(false);
    setUserInfo({
      photoURL: "",
      nom: "",
      prenom: "",
      role_id: null,
    });
    navigate("/");
    window.location.reload();
  };

  return (
    <nav
      className={`fixed top-0 left-0 w-full z-50 transition-all duration-300 ${
        scrolled
          ? "bg-gradient-to-b from-neutral-800 to-neutral-500"
          : "bg-transparent"
      }`}
    >
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
        <div className="text-4xl font-semibold font-passionOne">
          <button
            onClick={handleHomeClick}
            className="text-white focus:outline-none"
          >
            Mon
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 to-orange-600">
              Appli
            </span>
            Club
          </button>
        </div>
        <div className="lg:hidden">
          <button
            onClick={toggleMenu}
            aria-label="Menu"
            className="text-white focus:outline-none"
          >
            {isOpen ? (
              <IoIosClose className="text-3xl transition-transform duration-300 transform rotate-180" />
            ) : (
              <IoIosMenu className="text-3xl transition-transform duration-300" />
            )}
          </button>
        </div>
        <ul className={`hidden lg:flex space-x-8 text-white items-center`}>
          <li>
            <NavLink
              to="/"
              className="hover:text-gray-300 transition link-nav2"
            >
              Accueil
            </NavLink>
          </li>
          <li className="group relative">
            <button className="hover:text-gray-300 transition link-nav2 mt-2">
              Produits
            </button>
            <ul className="absolute hidden group-hover:block bg-neutral-800 text-white min-w-[200px] p-6 rounded-md space-y-4 top-full left-[-50px]">
              {" "}
              {/* left modifié */}
              <li>
                <NavLink
                  to="/product-monappliclub"
                  className="hover:text-orange-400 transition"
                >
                  MonAppliClub
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/product-monappliclub-light"
                  className="hover:text-orange-400 transition whitespace-nowrap"
                >
                  MonAppliClub "Light"
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/product-monappliclub-omnisports"
                  className="hover:text-orange-400 transition whitespace-nowrap"
                >
                  MonAppliClub "Omnisports"
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/product-judocoachpro"
                  className="hover:text-orange-400 transition whitespace-nowrap"
                >
                  Judo Coach Pro
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/product-judopresencepro"
                  className="hover:text-orange-400 transition whitespace-nowrap"
                >
                  Judo Présence Pro
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/partenaires-kata"
                  className="hover:text-orange-400 transition whitespace-nowrap"
                >
                  MonAppliKata
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/monapplicate"
                  className="hover:text-orange-400 transition whitespace-nowrap"
                >
                  MonAppliCaté
                </NavLink>
              </li>
            </ul>
          </li>

          <li>
            <NavLink
              to="/tarifs"
              className="hover:text-gray-300 transition link-nav2"
            >
              Tarifs
            </NavLink>
          </li>
          {/* Ajout du bouton switch pour Dark/Light mode */}
          <li className="flex items-center">
            <button
              onClick={toggleDarkMode}
              className="text-white focus:outline-none"
            >
              {isDarkMode ? (
                <FaSun className="text-2xl hover:text-yellow-400 transition" />
              ) : (
                <FaMoon className="text-2xl hover:text-blue-300 transition" />
              )}
            </button>
          </li>

          {isAuthenticated && (
            <>
              <li>
                <NavLink
                  to="/appli"
                  className="px-4 py-2 rounded-full transition bg-gradient-to-r from-yellow-400 to-orange-600 text-black font-lato text-base hover:from-yellow-500 hover:to-orange-700 whitespace-nowrap"
                >
                  Espace Client
                </NavLink>
              </li>
              <li className="flex items-center space-x-4">
                <img
                  src={userInfo.photoURL}
                  alt="Avatar"
                  className="h-8 w-8 rounded-full object-cover"
                />
                <span className="text-sm">
                  {userInfo.nom} {userInfo.prenom}
                </span>
                <FaSignOutAlt
                  className="text-xl cursor-pointer hover:text-orange-400 transition"
                  onClick={handleLogout}
                  title="Se déconnecter"
                />
              </li>
            </>
          )}
        </ul>
      </div>

      {isOpen && (
        <div className="lg:hidden bg-neutral-800 text-white w-full px-4 py-4 absolute top-full right-0 z-50">
          <ul className="flex flex-col items-center space-y-4 font-passionOne text-4xl font-thin">
            <li>
              <NavLink
                to="/"
                className="hover:text-orange-400 transition link-nav2"
                onClick={toggleMenu} // Fermer le menu lorsque l'utilisateur clique sur "Accueil"
              >
                Accueil
              </NavLink>
            </li>
            <li>
              <ul className="space-y-2 mt-2 mb-2 text-center">
                <li>
                  <NavLink
                    to="/product-monappliclub"
                    className="hover:text-orange-400 transition link-nav2 text-center"
                    onClick={toggleMenu} // Fermer le menu lorsque l'utilisateur clique sur "MonAppliClub"
                  >
                    MonAppliClub
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/product-monappliclub-light"
                    className="hover:text-orange-400 transition link-nav2 whitespace-nowrap"
                    onClick={toggleMenu} // Fermer le menu lorsque l'utilisateur clique sur "MonAppliClub Light"
                  >
                    MonAppliClub "Light"
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/product-monappliclub-omnisports"
                    className="hover:text-orange-400 transition link-nav2 whitespace-nowrap"
                    onClick={toggleMenu} // Fermer le menu lorsque l'utilisateur clique sur "MonAppliClub Light"
                  >
                    MonAppliClub "Omnisports"
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/product-judocoachpro"
                    className="hover:text-orange-400 transition link-nav2 whitespace-nowrap"
                    onClick={toggleMenu} // Fermer le menu lorsque l'utilisateur clique sur "MonAppliClub Light"
                  >
                    Judo Coach Pro
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/product-judopresencepro"
                    className="hover:text-orange-400 transition link-nav2 whitespace-nowrap"
                    onClick={toggleMenu} // Fermer le menu lorsque l'utilisateur clique sur "MonAppliClub Light"
                  >
                    Judo Presence Pro
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/partenaires-kata"
                    className="hover:text-orange-400 transition link-nav2 whitespace-nowrap"
                    onClick={toggleMenu} // Fermer le menu lorsque l'utilisateur clique sur "MonAppliClub Light"
                  >
                    MonAppliKata
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/monapplicate"
                    className="hover:text-orange-400 transition link-nav2 whitespace-nowrap"
                    onClick={toggleMenu} // Fermer le menu lorsque l'utilisateur clique sur "MonAppliClub Light"
                  >
                    MonAppliCaté
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink
                to="/tarifs"
                className="hover:text-orange-400 transition link-nav2"
                onClick={toggleMenu} // Fermer le menu lorsque l'utilisateur clique sur "Tarifs"
              >
                Tarifs
              </NavLink>
            </li>

            {/* Switch Dark/Light mode pour mobile */}
            <li>
              <button
                onClick={toggleDarkMode}
                className="text-white focus:outline-none"
              >
                {isDarkMode ? (
                  <FaSun className="text-2xl hover:text-yellow-400 transition" />
                ) : (
                  <FaMoon className="text-2xl hover:text-blue-300 transition" />
                )}
              </button>
            </li>

            {isAuthenticated && (
              <div className="flex gap-4 justify-between items-center">
                <li>
                  <NavLink
                    to="/appli"
                    className="px-4 py-2 rounded-full transition bg-gradient-to-r from-yellow-400 to-orange-600 text-black font-lato text-base hover:from-yellow-500 hover:to-orange-700"
                  >
                    Espace Client
                  </NavLink>
                </li>
                <li className="flex items-center gap-2">
                  <img
                    src={userInfo.photoURL}
                    alt="Avatar"
                    className="h-8 w-8 rounded-full object-cover"
                  />
                  <span className="text-sm font-lato">
                    {userInfo.nom} {userInfo.prenom}
                  </span>
                  <FaSignOutAlt
                    className="text-xl cursor-pointer hover:text-orange-400 transition mt-2"
                    onClick={handleLogout}
                    title="Se déconnecter"
                  />
                </li>
              </div>
            )}
          </ul>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
