import React, { useState } from "react";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";

const images = [
  {
    src: "/images/diaporama/001.png",
    alt: "Menu",
    caption: "Menu de l'Application",
  },
  {
    src: "/images/diaporama/002.png",
    alt: "Formules",
    caption: "Formules à petits prix pour les clubs",
  },
  {
    src: "/images/diaporama/003.png",
    alt: "Création du Club",
    caption: "Création du Club par le dirigeant",
  },
  {
    src: "/images/diaporama/004.png",
    alt: "Création des Dojo & Groupes",
    caption: "Création des Dojo & Groupes par les dirigeants",
  },
  {
    src: "/images/diaporama/005.png",
    alt: "Ajoût des Judokas",
    caption:
      "Importation des judokas par fichier Excel ou inscription individuelle",
  },
  {
    src: "/images/diaporama/006.png",
    alt: "Création des enseignants et invitations",
    caption:
      "Création des enseignants et invitations à utiliser l'application gratuitement",
  },
  {
    src: "/images/diaporama/007.png",
    alt: "Page de Présence",
    caption:
      "Les enseignants peuvent faire l'appel de présence des judokas par Dojo et Groupe. Ils ne voient que les judokas de leur groupe.",
  },
  {
    src: "/images/diaporama/008.png",
    alt: "Tableau de bord des présences",
    caption: "Tableau de bord des présences par Dojo et Groupe",
  },
  {
    src: "/images/diaporama/009.png",
    alt: "Statistiques",
    caption: "Page de statistiques des présences par Dojo et Groupe",
  },
];

const DiaporamaLight = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const { src, alt, caption } = images[currentImageIndex];

  return (
    <div className="relative py-16 bg-base-100 dark:bg-neutral-900 text-gray-800 dark:text-gray-200">
      <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
        <p className="mb-5 font-light text-gray-500 sm:text-xl dark:text-gray-400">
          Voici une sélection de quelques pages de l'application.
        </p>
      </div>

      {/* Carrousel */}
      <div className="relative flex flex-col items-center justify-center">
        {/* Bouton pour image précédente */}
        <button
          className="absolute left-4 md:left-8 bg-orange-400 dark:bg-orange-600 text-white p-3 rounded-full focus:outline-none z-10"
          onClick={handlePrev}
        >
          <FaArrowLeft className="text-xl" />
        </button>

        {/* Conteneur d'image centré */}
        <div className="w-full mx-auto flex justify-center">
          <img
            src={src}
            alt={alt}
            className="w-full max-w-[90%] sm:max-w-[600px] md:max-w-[800px] lg:max-w-[1000px] h-auto rounded-lg object-cover shadow-lg"
          />
        </div>

        {/* Légende sous l'image */}
        <p className="text-center text-gray-600 dark:text-gray-400 mt-4 font-medium">
          {caption}
        </p>

        {/* Bouton pour image suivante */}
        <button
          className="absolute right-4 md:right-8 bg-orange-400 dark:bg-orange-600 text-white p-3 rounded-full focus:outline-none z-10"
          onClick={handleNext}
        >
          <FaArrowRight className="text-xl" />
        </button>
      </div>
    </div>
  );
};

export default DiaporamaLight;
