import React, { useState, useEffect } from "react";
import DOMPurify from "dompurify"; // Pour assainir le texte enrichi
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  getAllRequests,
  updateRequest,
} from "./../../../../services/requestsServices";

const RequestsList = () => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedScreenshot, setSelectedScreenshot] = useState(null);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [newDescription, setNewDescription] = useState("");

  useEffect(() => {
    const fetchRequests = async () => {
      setLoading(true);
      try {
        const data = await getAllRequests();
        if (Array.isArray(data) && data.length > 0) {
          setRequests(data);
        } else {
          setRequests([]);
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des requêtes :", error);
        setRequests([]);
      } finally {
        setLoading(false);
      }
    };
    fetchRequests();
  }, []);

  const handleScreenshotClick = (url) => {
    setSelectedScreenshot(url);
  };

  const closeModal = () => {
    setSelectedScreenshot(null);
  };

  const handleStatusChange = async (requestId, currentStatus) => {
    const nextStatus =
      currentStatus === "Pending"
        ? "In Progress"
        : currentStatus === "In Progress"
        ? "Completed"
        : "Pending";

    try {
      await updateRequest(requestId, { status: nextStatus });
      setRequests((prevRequests) =>
        prevRequests.map((req) =>
          req.id === requestId ? { ...req, status: nextStatus } : req
        )
      );
    } catch (error) {
      console.error("Erreur lors de la mise à jour du statut :", error);
    }
  };

  const openEditModal = (request) => {
    setSelectedRequest(request);
    setNewDescription(request.description);
  };

  const closeEditModal = () => {
    setSelectedRequest(null);
    setNewDescription("");
  };

  const handleUpdateRequest = async () => {
    if (!selectedRequest) return;
    try {
      await updateRequest(selectedRequest.id, { description: newDescription });
      setRequests((prevRequests) =>
        prevRequests.map((req) =>
          req.id === selectedRequest.id
            ? { ...req, description: newDescription }
            : req
        )
      );
      closeEditModal();
    } catch (error) {
      console.error("Erreur lors de la mise à jour de la requête :", error);
    }
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Résumé des Requêtes</h2>
      {loading ? (
        <p>Chargement des données...</p>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {requests.map((request) => (
            <div
              key={request.id}
              className="bg-white p-4 shadow-lg rounded-lg border"
            >
              <div className="flex items-center space-x-4">
                <img
                  src={request.Associations.logo_asso}
                  alt={request.Associations.nom_asso}
                  className="w-10 h-10 rounded-full"
                />
                <span className="font-semibold">
                  {request.Associations.nom_asso}
                </span>
              </div>
              <h3 className="text-lg font-semibold mt-2">{request.title}</h3>
              <p
                className="mt-2 text-gray-600"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(request.description),
                }}
              ></p>

              {/* Tags */}
              <div className="flex items-center mt-3 space-x-2">
                <span
                  onClick={() => handleStatusChange(request.id, request.status)}
                  className={`cursor-pointer px-3 py-1 rounded text-white text-sm ${
                    request.status === "Pending"
                      ? "bg-yellow-500"
                      : request.status === "In Progress"
                      ? "bg-blue-500"
                      : "bg-green-500"
                  }`}
                >
                  {request.status}
                </span>

                <span
                  className={`px-3 py-1 rounded text-white text-sm ${
                    request.priority === "Low"
                      ? "bg-green-400"
                      : request.priority === "Medium"
                      ? "bg-yellow-500"
                      : "bg-red-500"
                  }`}
                >
                  {request.priority}
                </span>
              </div>

              {/* Date de création */}
              <p className="text-sm text-gray-500 mt-2">
                Créé le : {new Date(request.createdAt).toLocaleDateString()}
              </p>

              {/* Boutons */}
              <div className="mt-4 flex justify-between">
                {request.screenshotUrl && (
                  <button
                    className="text-blue-500 underline"
                    onClick={() => handleScreenshotClick(request.screenshotUrl)}
                  >
                    Voir capture
                  </button>
                )}
                <button
                  className="bg-gray-200 text-gray-700 px-3 py-1 rounded"
                  onClick={() => openEditModal(request)}
                >
                  Modifier
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Modale Screenshot */}
      {selectedScreenshot && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
          onClick={closeModal}
        >
          <div
            className="bg-white p-4 rounded-lg max-w-4xl w-full relative"
            onClick={(e) => e.stopPropagation()}
          >
            <img
              src={selectedScreenshot}
              alt="Capture d'écran"
              className="w-full h-auto cursor-pointer"
              onClick={closeModal}
            />
            <button
              className="absolute top-2 right-2 text-red-500 font-bold text-lg"
              onClick={closeModal}
            >
              X
            </button>
          </div>
        </div>
      )}

      {/* Modale d'édition */}
      {selectedRequest && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg w-2/3">
            <h3 className="text-xl font-semibold mb-4">Modifier la requête</h3>
            <ReactQuill
              value={newDescription}
              onChange={setNewDescription}
              className="mb-4"
            />
            <div className="flex justify-end space-x-2">
              <button
                className="bg-red-500 text-white px-4 py-2 rounded"
                onClick={closeEditModal}
              >
                Annuler
              </button>
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded"
                onClick={handleUpdateRequest}
              >
                Sauvegarder
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RequestsList;
