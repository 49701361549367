import React from "react";
import ComponentTitle from "../Home/ComponentTitle";
import useScrollToTop from "../../hooks/useScrollToTop";

const AboutJudoPresencePro = () => {
  useScrollToTop();

  return (
    <section
      id="about"
      className="py-16 bg-gray-100 dark:bg-neutral-900 text-gray-800 dark:text-gray-200"
    >
      <div className="container mx-auto px-4">
        {/* Titre principal */}
        <div className="mx-auto max-w-screen-md text-center mb-12">
          <ComponentTitle title="Découvrez" highlight=" les Formules" />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {/* Mode Démo */}
          <div className="bg-slate-200 dark:bg-neutral-800 p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-bold mb-4 bg-green-400 dark:bg-green-600 p-2 rounded-md shadow-md text-center">
              🥋 Mode Démo
            </h3>
            <p className="text-center text-green-600 dark:text-green-400 font-semibold mb-4 text-2xl">
              Gratuit
            </p>
            <ul className="list-none">
              <li className="mb-4">
                ✔️ Permet de vous familiariser avec l'application
              </li>
              <li className="mb-4">
                ✔️ Accès à des statistiques et des données fictives
              </li>
            </ul>
          </div>

          {/* Présence */}
          <div className="bg-slate-200 dark:bg-neutral-800 p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-bold mb-4 bg-blue-200 dark:bg-blue-500 p-2 rounded-md shadow-md text-center">
              🥋 Présence
            </h3>
            <p className="text-center text-blue-600 dark:text-blue-400 font-semibold mb-4 text-2xl">
              10 €
            </p>
            <ul className="list-none">
              <li className="mb-4">
                ✔️ Gestion du Club, Dojos, Groupes, Enseignants et Adhérents,
              </li>
              <li className="mb-4">
                ✔️ Invitation des enseignants sur les groupes de votre club,
              </li>
              <li className="mb-4">
                ✔️ Gestion des Présences et des Absences des adhérents,
              </li>
              <li className="mb-4">✔️ Tableau de Bord & Statistiques,</li>
              <li className="mb-4">✔️ Limité à 1 Dojo, -100 adhérents.</li>
            </ul>
          </div>

          {/* Presence Pro */}
          <div className="bg-slate-200 dark:bg-neutral-800 p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-bold mb-4 bg-blue-300 dark:bg-blue-500 p-2 rounded-md shadow-md text-center">
              🥋 Présence Pro
            </h3>
            <p className="text-center text-blue-600 dark:text-blue-400 font-semibold mb-4 text-2xl">
              20 €
            </p>
            <ul className="list-none">
              <li className="mb-4">✔️ Formule Presence plus ...</li>
              <li className="mb-4">
                ✔️ Gestion jusqu'à 3 Dojo, jusqu'à 200 adhérents.
              </li>
            </ul>
          </div>

          {/* Présence Ultim */}
          <div className="bg-slate-200 dark:bg-neutral-800 p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-bold mb-4 bg-blue-400 dark:bg-blue-500 p-2 rounded-md shadow-md text-center">
              🥋 Présence Ultim
            </h3>
            <p className="text-center text-blue-600 dark:text-blue-400 font-semibold mb-4 text-2xl">
              30 €
            </p>
            <ul className="list-none">
              <li className="mb-4">✔️ Formule Presence Pro plus ...</li>
              <li className="mb-4">
                ✔️ Gestion de + 3 Dojo, plus de 200 adhérents.
              </li>
            </ul>
          </div>
        </div>

        {/* Appel à l'action */}
        <div className="mt-12 text-center mx-auto">
          <button
            onClick={() =>
              window.open("https://www.judo-presence-pro.com/", "_blank")
            }
            className="px-8 py-3 bg-gradient-to-r from-yellow-400 to-orange-600 text-white rounded-full transition relative group w-96"
          >
            <span className="group-hover:opacity-0 transition-opacity duration-300">
              Découvrir Judo Présence Pro
            </span>
            <span className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 font-passionOne text-3xl font-light">
              Judo Présence Pro
            </span>
          </button>
        </div>
      </div>
    </section>
  );
};

export default AboutJudoPresencePro;
