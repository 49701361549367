import React, { useState, useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import Button from "../../../../components/Button/Button";
import ButtonEdit from "../../../../components/Button/ButtonEdit";
import ButtonDelete from "../../../../components/Button/ButtonDelete";
import {
  getAllAssociations,
  deleteAssociation as deleteAssociationAPI,
} from "../../../../services/associationsServices";
import useScrollToTop from "../../../../hooks/useScrollToTop";
import { ToastContainer, toast } from "react-toastify";
import AddAssociationModal from "./AddAssociationModal";
import EditAssociationModal from "./EditAssociationModal";

const ListingAssociations = () => {
  useScrollToTop();
  const [associations, setAssociations] = useState([]);
  const [displayedAssociations, setDisplayedAssociations] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStatut, setSelectedStatut] = useState(""); // Filtre par statut
  const [selectedDiscipline, setSelectedDiscipline] = useState(""); // Filtre par discipline
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editAssociationId, setEditAssociationId] = useState(null);

  const fetchData = async () => {
    try {
      const associationsData = await getAllAssociations();
      setAssociations(associationsData || []);
      setDisplayedAssociations(associationsData || []);
    } catch (error) {
      console.error("Erreur lors du chargement des associations:", error);
      toast.error("Erreur lors du chargement des associations");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearchChange = (e) => {
    const searchTermLocal = e.target.value;
    setSearchTerm(searchTermLocal);
    filterAssociations(searchTermLocal, selectedStatut, selectedDiscipline);
  };

  const handleStatutChange = (e) => {
    const statut = e.target.value;
    setSelectedStatut(statut);
    filterAssociations(searchTerm, statut, selectedDiscipline);
  };

  const handleDisciplineChange = (e) => {
    const discipline = e.target.value;
    setSelectedDiscipline(discipline);
    filterAssociations(searchTerm, selectedStatut, discipline);
  };

  const filterAssociations = (term, statut, discipline) => {
    const filteredAssociations = associations.filter((association) => {
      const matchesSearchTerm =
        association.nom_asso.toLowerCase().includes(term.toLowerCase()) ||
        association.discipline_asso.toLowerCase().includes(term.toLowerCase());

      const matchesStatut = statut
        ? association.statut_asso.toLowerCase() === statut.toLowerCase()
        : true;

      const matchesDiscipline = discipline
        ? association.discipline_asso
            .toLowerCase()
            .split(", ")
            .includes(discipline.toLowerCase())
        : true;

      return matchesSearchTerm && matchesStatut && matchesDiscipline;
    });
    setDisplayedAssociations(filteredAssociations);
  };

  const deleteAssociation = async (associationId) => {
    if (
      window.confirm(
        `Êtes-vous sûr de vouloir supprimer cette association ? ID: ${associationId}`
      )
    ) {
      try {
        const message = await deleteAssociationAPI(associationId);
        toast.success(message);
        setAssociations(
          associations.filter((association) => association.id !== associationId)
        );
        setDisplayedAssociations(
          associations.filter((association) => association.id !== associationId)
        );
      } catch (error) {
        console.error("Erreur lors de la suppression de l'association:", error);
        toast.error("Erreur lors de la suppression de l'association");
      }
    }
  };

  const getStatusDisplay = (statut) => {
    switch (statut.toLowerCase()) {
      case "client":
        return { name: "Client", className: "bg-green-500 text-white" };
      case "prospect":
        return { name: "Prospect", className: "bg-orange-500 text-white" };
      case "ancien client":
        return { name: "Ancien Client", className: "bg-gray-500 text-white" };
      case "prof.":
        return { name: "Prof.", className: "bg-yellow-500 text-white" };
      default:
        return { name: "Statut Inconnu", className: "bg-blue-500 text-white" };
    }
  };

  const openAddModal = () => {
    setShowAddModal(true);
  };

  const closeAddModal = () => {
    setShowAddModal(false);
  };

  const openEditModal = (associationId) => {
    setEditAssociationId(associationId);
    setShowEditModal(true);
  };

  const closeEditModal = () => {
    setShowEditModal(false);
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-light tracking-wide text-gray-800 dark:text-white mb-4">
        Liste des Associations
      </h1>

      <div className="w-full mx-auto mb-4">
        <Button
          text="Ajouter Association"
          onClick={openAddModal}
          icon={FaPlus}
          className="w-full gap-2 mb-4"
        />

        <div className="flex flex-col md:flex-row justify-between mb-2 gap-2">
          <div className="w-full md:w-1/3">
            <select
              className="bg-gray-800 text-gray-400 border border-gray-700 rounded-lg py-2 px-3 w-full"
              value={selectedStatut}
              onChange={handleStatutChange}
            >
              <option value="">Tous les statuts</option>
              <option value="Client">Client</option>
              <option value="Prospect">Prospect</option>
              <option value="Ancien Client">Ancien Client</option>
            </select>
          </div>
          <div className="w-full md:w-1/3">
            <select
              className="bg-gray-800 text-gray-400 border border-gray-700 rounded-lg py-2 px-3 w-full"
              value={selectedDiscipline}
              onChange={handleDisciplineChange}
            >
              <option value="">Toutes les disciplines</option>
              {Array.from(
                new Set(
                  associations
                    .flatMap((assoc) => assoc.discipline_asso.split(", "))
                    .map((discipline) => discipline.trim())
                )
              ).map((discipline) => (
                <option key={discipline} value={discipline}>
                  {discipline}
                </option>
              ))}
            </select>
          </div>
          <div className="w-full md:w-1/3">
            <input
              className="bg-gray-800 text-gray-400 border border-gray-700 rounded-lg py-2 px-3 w-full"
              type="text"
              placeholder="Rechercher..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
        </div>

        <div className="text-left font-base mb-2">
          Nombre d'associations affichées : {displayedAssociations.length}
        </div>

        {/* Table for desktop, hidden on mobile */}
        <div className="hidden md:block overflow-x-auto rounded-lg bg-gray-800 dark:bg-gray-900 p-4">
          <table className="w-full text-left text-sm text-gray-400">
            <thead className="border-b border-gray-700">
              <tr>
                <th className="pb-3 px-4 text-gray-400">Fav</th>
                <th className="pb-3 px-4 text-gray-400">id</th>
                <th className="pb-3 px-4 text-gray-400">Logo</th>
                <th className="pb-3 px-4 text-gray-400">Nom de l'Association</th>
                <th className="pb-3 px-4 text-gray-400">Statut</th>
                <th className="pb-3 px-4 text-gray-400">Discipline</th>
                <th className="pb-3 px-4 text-gray-400">Utilisateurs</th>
                <th className="pb-3 px-4 text-gray-400">Actions</th>
              </tr>
            </thead>
            <tbody>
              {displayedAssociations.map((association) => (
                <tr key={association.id} className="border-b border-gray-700">
                  <td className="py-2 px-4 text-white">
                    {association.is_favorite ? "⭐" : ""}
                  </td>
                  <td className="py-2 px-4 text-gray-400">{association.id}</td>
                  <td className="py-2 px-4">
                    <img
                      className="rounded-full w-16 h-auto object-center object-cover"
                      src={association.logo_asso || "/img/default_logo.png"}
                      alt="Logo"
                    />
                  </td>
                  <td className="py-2 px-4 text-white">
                    {association.nom_asso}
                  </td>
                  <td className="py-2 px-4">
                    {(() => {
                      const { name, className } = getStatusDisplay(
                        association.statut_asso
                      );
                      return (
                        <span
                          className={`px-2 py-1 rounded-full text-xs ${className}`}
                        >
                          {name}
                        </span>
                      );
                    })()}
                  </td>
                  <td className="py-2 px-4 text-gray-400">
                    {association.discipline_asso}
                  </td>

                  <td className="py-2 px-4 text-gray-400">
                    {association.Users && association.Users.length > 0 ? (
                      association.Users.map((user, index) => (
                        <div key={index} className="flex items-center gap-2">
                          <img
                            className="rounded-full w-8 h-8 object-cover"
                            src={user.photoURL || "/img/user_avatar.jpg"}
                            alt="Avatar"
                          />
                          <span className="text-xs text-gray-200">
                            {user.nom} {user.prenom}
                          </span>
                        </div>
                      ))
                    ) : (
                      <span>Aucun utilisateur associé</span>
                    )}
                  </td>
                  <td className="py-2 px-4 flex gap-2">
                    <ButtonEdit
                      onClick={() => openEditModal(association.id)}
                      hoverLabel="Éditer"
                    />
                    <ButtonDelete
                      onClick={() => deleteAssociation(association.id)}
                      hoverLabel="Supprimer"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Cards for mobile, hidden on desktop */}
        <div className="block md:hidden">
          {displayedAssociations.map((association) => (
            <div
              key={association.id}
              className="flex bg-gray-800 dark:bg-gray-900 rounded-lg shadow-md mb-4"
            >
              {/* Partie gauche (1/5) */}
              <div className="w-3/10 bg-gray-700 p-4 flex flex-col items-center justify-center gap-2">
                <span
                  className={`px-2 py-1 rounded-full text-xs whitespace-nowrap mb-2 ${
                    getStatusDisplay(association.statut_asso).className
                  }`}
                >
                  {getStatusDisplay(association.statut_asso).name}
                </span>
                <ButtonEdit
                  onClick={() => openEditModal(association.id)}
                  hoverLabel="Éditer"
                  className="text-white"
                />
                <ButtonDelete
                  onClick={() => deleteAssociation(association.id)}
                  hoverLabel="Supprimer"
                  className="text-white"
                />
              </div>

              {/* Partie droite (4/5) */}
              <div className="w-7/10 p-4">
                <div className="text-white text-lg mb-2">
                  {association.nom_asso}
                </div>
                <div className="text-gray-400 text-sm">
                  {association.discipline_asso}
                </div>
                <div className="text-gray-400 text-sm">
                  {association.siteweb_asso ? (
                    <a
                      href={association.siteweb_asso}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:underline"
                    >
                      {association.siteweb_asso}
                    </a>
                  ) : (
                    "N/A"
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <AddAssociationModal
        isOpen={showAddModal}
        onClose={closeAddModal}
        refreshList={fetchData}
      />

      {editAssociationId && (
        <EditAssociationModal
          isOpen={showEditModal}
          onClose={closeEditModal}
          associationId={editAssociationId}
          refreshList={fetchData}
        />
      )}

      <ToastContainer />
    </div>
  );
};

export default ListingAssociations;
